var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"header mb-2 pl-2 py-4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"24px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t("doc5.header"))+" "+_vm._s(_vm.$t("non_im.(DependentStudent)")))]),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"grey","dark":"","small":""},on:{"click":function($event){return _vm.opendialoghelp()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-help")]),_vm._v("Status")],1)],1),_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","width":"100vw"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"search","solo":"","dense":""}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
            'All',
            'Waiting for verification',
            'Request verified',
            'Approved',
            'Pending',
            'Declined' ],"label":"Filter","solo":"","dense":""},on:{"change":function($event){return _vm.statusFilters(_vm.statusFilter)}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.EngLang)?_c('v-data-table',{attrs:{"headers":_vm.headersEN,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name_of_user))])]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt))+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'Waiting for verification'
                ? 'orange'
                : item.status == 'Request verified'
                ? 'blue'
                : item.status == 'Approved'
                ? 'green'
                : item.status == 'Pending'
                ? 'orange'
                : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(_vm.showstatus)?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.changeStatus(item)}}},on),[_vm._v("mdi-checkbox-marked")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Change status")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(
                  _vm.userData.user_type == 'ADMIN' ||
                  _vm.userData.user_type == 'SUPERADMIN'
                )?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.onClickExport(item)}}},on),[_vm._v("mdi-download")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.status !== 'SAVE DRAFT')?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.goToView(item.id)}}},on),[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(
                  _vm.userData.user_type == 'ADMIN' ||
                  _vm.userData.user_type == 'SUPERADMIN'
                    ? true
                    : item.status == 'SAVE DRAFT'
                    ? true
                    : false
                )?_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"disabled":item.status === 'Approved'},on:{"click":function($event){return _vm.goToEdit(item.id)}}},on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.DeleteItem(item.id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)}):_vm._e(),(!_vm.EngLang)?_c('v-data-table',{attrs:{"headers":_vm.headersTH,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name_of_user))])]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt))+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'Waiting for verification'
                ? 'orange'
                : item.status == 'Request verified'
                ? 'blue'
                : item.status == 'Approved'
                ? 'green'
                : item.status == 'Pending'
                ? 'orange'
                : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(_vm.showstatus)?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.changeStatus(item)}}},on),[_vm._v("mdi-checkbox-marked")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Change status")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(
                  _vm.userData.user_type == 'ADMIN' ||
                  _vm.userData.user_type == 'SUPERADMIN'
                )?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.onClickExport(item)}}},on),[_vm._v("mdi-download")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.status !== 'SAVE DRAFT')?_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.goToView(item.id)}}},on),[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(
                  _vm.userData.user_type == 'ADMIN' ||
                  _vm.userData.user_type == 'SUPERADMIN'
                    ? true
                    : item.status == 'SAVE DRAFT'
                    ? true
                    : false
                )?_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"disabled":item.status === 'Approved'},on:{"click":function($event){return _vm.goToEdit(item.id)}}},on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",on:{"click":function($event){return _vm.DeleteItem(item.id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)}):_vm._e()],1)],1),_c('DialogStatus',{attrs:{"dialog1":_vm.dialog,"status":_vm.statusitem,"EngLang":_vm.EngLang,"URLenpoint":_vm.URLenpoint},on:{"closeDialog":function($event){return _vm.closeDialog()}}}),_c('DialogStatusDetail',{attrs:{"dialoghelp1":_vm.dialoghelp,"EngLang":_vm.EngLang},on:{"closeDialoghelp":function($event){return _vm.closeDialoghelp()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }